@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "satoshi";
  src: url("../public/fonts/satoshi/Satoshi-Regular.woff");
}

@font-face {
  font-family: "satoshi-medium";
  src: url("../public/fonts/satoshi/Satoshi-Medium.woff");
}

@font-face {
  font-family: "satoshi-light";
  src: url("../public/fonts/satoshi/Satoshi-Light.woff");
}

@font-face {
  font-family: "satoshi-bold";
  src: url("../public/fonts/satoshi/Satoshi-Bold.woff");
}

@font-face {
  font-family: "satoshi-black";
  src: url("../public/fonts/satoshi/Satoshi-Black.woff");
}


@font-face {
  font-family: "proxima";
  src: url("../public/fonts/proxima/ProximaNovaA-Regular.ea830e6b.woff");
}

@font-face {
  font-family: "proxima-bold";
  src: url("../public/fonts/proxima/ProximaNovaA-Semibold.a3357887.woff");
}

@font-face {
  font-family: "firma";
  src: url("../public/fonts/BRFirmaCW/BRFirmaCW-Regular.woff2");
}

@font-face {
  font-family: "firma-bold";
  src: url("../public/fonts/BRFirmaCW/BRFirmaCW-Bold.woff2");
}

@font-face {
  font-family: "firma-semi-bold";
  src: url("../public/fonts/BRFirmaCW/BRFirmaCW-SemiBold.woff2");
}

html {
  @apply font-proxima;
}